<template>
  <div class="church-communications template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcGideonCardContacts }}</h1>
      <BackJump @reload_host="handleFacilityChange()" :obj="backJumpObj" :vis="true" :i18n="translations.components">
      </BackJump>
    </page-header>
    <page-body class="mx-lg">
      <section class="section-1">
        <div class="d-flex section-header">
          <h2>{{ translations.tcContacts }}</h2>
          <b-button variant="primary"
            v-if="iCanSee(secured_contact_add_controls.add_gideoncard_facility_contact_button)"
            @click="handleAddContactClick">{{ translations.tcAddContact }}</b-button>
        </div>
        <div class="d-flex flex-wrap" v-if='translations.components'>
          <ServiceCard v-for="(contact, index) in contacts" :i18n="translations.components" :key="index" :obj="contact"
            :secure_edit_keys="secured_contact_edit_controls" :secure_delete_keys="secured_contact_delete_controls"
            @edit_requested="handleContactEditRequest(contact)" @delete_approved="handleDeleteRequest(contact)"
            @profile_requested="handleContactProfileRequest(contact)"></ServiceCard>
        </div>
      </section>
    </page-body>
    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJumpGideonCards.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ServiceCard from '@/components/service-card/ServiceCard.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'card_contacts',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      backJumpObj: {
        link: '#',
        location: '',
      },
      secured_contact_add_controls: {
        add_gideoncard_facility_contact_button: '29869175-c81f-4880-bbc7-b0602bf24380',
      },
      secured_contact_edit_controls: {
        edit_gideoncard_facility_contact_button: 'b22ddc74-c124-4d4a-a8d9-455af8e5a23b',
      },
      secured_contact_delete_controls: {
        delete_gideoncard_facility_contact_button: '15e4b32d-a73e-4a30-8aea-36e51cc30f02',
      },
    }
  },
  methods: {
    ...mapActions({
      loadContacts: 'card/getFacilityContacts',
      loadProfile: 'card/getProfile',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setSelectedLocationContact: 'user/setSelectedLocationContact',
      upsertContact: 'card/upsertContact',
    }),
    async handleAddContactClick() {
      await this.setSelectedLocationContact(null)
      this.$router.push('/programs/gc/card_facility_edit_contact')
    },
    async handleAddGideonRepClick(ct) {
      await this.setSelectedChurchContact(ct)
      await this.setActiveChurchContactType(1)
      this.$router.push('/programs/cm/churchProfile/add-church-contact/1')
    },
    async handleFacilityChange() {
      await this.setSelectedFacilityKey(this.userSelectedFacilityKey)
      this.pageLoad()
    },
    async handleContactEditRequest(ct) {
      await this.setSelectedLocationContact(ct)
      this.$router.push('/programs/gc/card_facility_edit_contact')
    },
    async handleContactProfileRequest(contact) {
      await this.setSelectedIndividualKey(contact.ixo_ind_key)
      this.$router.push({ path: '/programs/mb/member-profile/' })
    },
    async handleDeleteRequest(ct) {
      let npk = this.contacts.filter(cf => cf.ixo_ind_key !== ct.ixo_ind_key)
      let data = {
        ind_key: ct.ixo_ind_key,
        ind_name: ct.name,
        irt_key: ct.ixo_irt_key,
        ixo_key: ct.ixo_key,
        title: ct.ixo_title,
        primary: ct.primary_ind_word === 'Yes',
        member_key: this.userId,
        org_key: this.userSelectedFacilityKey,
        delete: true,
        new_primary_key: npk.length > 0 ? npk[0].ixo_ind_key : null
      }
      try {
        this.setLoadingStatus(true)
        let response = await this.upsertContact(data)
        this.setLoadingStatus(false)
        if (response != '') {
          await this.pageLoad()
          this.$swal({
            icon: 'success',
            confirmButtonText: this.translations.tcOk,
            text: this.translations.tcDeleteContactSuccessfully,
          })
        }
      } catch (error) {
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcDeleteContactFailure,
        })
      }
    },
    async pageLoad() {
      await Promise.all([this.setLoadingStatus(true),
      this.loadProfile(this.userSelectedFacilityKey),
      this.loadContacts(this.userSelectedFacilityKey)
      ]).then(() => {
        this.backJumpObj.location = this.profileSummary.org_name
        this.backJumpObj.link = `/programs/gc/profile/summary/card_profile`
        this.setLoadingStatus(false)
      })
    },
  },
  computed: {
    ...mapGetters({
      contacts: 'card/getContacts',
      iCanSee: 'user/iCanSee',
      prefCulture: 'user/userPreferredCulture',
      profileSummary: 'card/getFacilitySummary',
      userId: 'user/userId',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey'
    })
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('back-jump-gideon-cards', 'quick-jump-gideon-cards', 'service-card'),
      this.getComponentTranslations('common.individual-relationship-type'),
      this.pageLoad(),
    ]).then((results) => {
      this.stripReadableText(results[2])
      const translatedText = {
        ...results[1],
        common: { ...this.translations.common }
      }
      this.$set(this.translations, 'components', translatedText)
    })
  },
  components: {
    BackJump: BackJump,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ServiceCard: ServiceCard
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.church-communications {
  header h1 {
    padding-bottom: 80px;

    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }

  .section-header {
    margin-bottom: 45px;
    align-items: center;

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }

    h2 {
      font-size: 42px;
      font-family: $DIN;
      line-height: 44px;
      letter-spacing: 1px;
      color: #000;
      margin-right: 30px;
      margin-bottom: 0;
      text-transform: uppercase;

      @include breakpoint(sm) {
        margin-bottom: 35px;
      }
    }

    .btn {
      margin-right: 10px;
      display: flex;
      align-items: center;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .section-1,
  .section-2 {
    padding-left: 25px;
    padding-right: 25px;

    @include breakpoint(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .section-1 {
    margin-bottom: 75px;
    margin-top: 30px;

    @include breakpoint(sm) {
      margin-bottom: 15px;
    }
  }

  .section-2 {
    margin-bottom: 20px;
  }
}
</style>
